<template>
  <v-layout column>
    <InfoSwitch
      :value.sync="internalValue"
      :label="label"
      :autosave="autosave"
      :syncId="syncId"
    ></InfoSwitch>
    <v-layout column class="mt-2">
      <slot></slot>
    </v-layout>
  </v-layout>
</template>

<script>
import ProxyModificationMixin from "@/mixins/ProxyModificationMixin"

export default {
  mixins: [ProxyModificationMixin],
  props: {
    value: {},
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  components: {
    InfoSwitch: () => import("@/components/controls/InfoSwitch"),
  },
};
</script>